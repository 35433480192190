import { applySnapshot, getSnapshot, isAlive, types } from "mobx-state-tree";
import { DATE_TIME_FORMAT, EMPTY_OBJECT_ID } from "modules/common/constants";
import {
  BankDetails,
  ShortBankDetails,
  fields as bankDetailsFields,
  initialState as bankDetailsInitialState,
  formatBankDetails,
} from "modules/common/models/bank-details";
import { DictionaryLink } from "modules/common/models/dictionary-link";
import { BaseEntity, isNewlyCreated } from "modules/common/models/entity";
import { FileMetadata } from "modules/common/models/file-metadata";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { MimeTypes } from "modules/common/services/files";
import { getFieldLabel } from "modules/common/services/form/fields";
import { formatDate } from "modules/common/services/formatting/date";
import { formatPhoneNumber } from "modules/common/services/formatting/phone";
import { nameof } from "modules/common/services/typescript";
import { texts } from "modules/common/texts";
import { WorkType } from "modules/dictionaries/work-types/models/work-type";
import { RoleAccessRecord, RoleData } from "modules/session/access/models/access-store";
import moment from "moment";
import Schema, { LegalNameLabel, PhysycalNameLabel } from "../components/details/validation";
import { OutsourcerPayment } from "./outsourcer-payment";
import { Passport, initialState as emptyPassport, fields as passportFields } from "./passport";

export const ACCEPT = [MimeTypes.jpeg, MimeTypes.png, MimeTypes.pdf].join(",");

export const SupplierDocument = FileMetadata;

export const OrderPost = types
  .model({
    id: types.string,
    date: types.string,
    time: types.string,
    order: types.maybeNull(DictionaryLink),
    employer: types.maybeNull(DictionaryLink),
    isRequest: types.boolean,
    subject: types.string,
    message: types.string,
    recipients: types.array(types.model({ id: types.string, name: types.string, email: types.string })),
    attachments: types.array(
      types.model({
        id: types.string,
        name: types.string,
      })
    ),
  })
  .named("OrderPost");

export const OrderPostStore = types
  .compose(Transport, Notificator, types.model({ list: types.array(OrderPost) }))
  .actions((self) => ({
    loadSendEmails: flow(function* (orderId?: string) {
      try {
        const data: OrderPostType[] = yield self.transport.post<OrderPostType[]>(
          apiUrls.outsourcers.getRequestEmailSend(),
          { orderId }
        );

        applySnapshot(self.list, data);
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    readMailPost: flow(function* () {
      try {
        yield self.transport.get<any>(apiUrls.outsourcers.readMailPost());

        self.notify.success("Данные успешно синхронизированы!");
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .actions((self) => ({
    saveSendEmail: flow(function* (
      selectedAgents: SelectItem[],
      selectedOrder: null | SelectItem,
      uploadedFiles: FileBase[],
      agentsList: any[],
      subject: string,
      message: string
    ) {
      try {
        const order = selectedOrder ? { id: selectedOrder.id, name: selectedOrder.label } : null;
        const documentIds = uploadedFiles.map((file) => file.fileId);
        const agentsIds = selectedAgents.map((a) => a.id);
        const agents = agentsList.filter((a) => a.isAgent && agentsIds.includes(a.id));
        const recipients = agents.map((a) => ({ id: a.id, name: a.name, email: a.email }));
        const body = { order, documentIds, subject, message, recipients, isRequest: true };

        const snapshot = yield self.transport.post<any>(apiUrls.outsourcers.requestEmailSave(), body);
        if (snapshot) {
          self.notify.success(texts.messages.saved);
        }
        yield self.loadSendEmails(order?.id);
        return true;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),
    saveSendEmailOrder: flow(function* (
      messageId: null | string,
      selectedOrder: null | SelectItem,
      changeDate: boolean
    ) {
      if (!selectedOrder) {
        self.notify.error("Выберите договор!");
        return false;
      }
      try {
        const order = selectedOrder ? { id: selectedOrder.id, name: selectedOrder.label } : null;

        const body = { order, messageId, changeDate };

        const snapshot = yield self.transport.post<any>(apiUrls.outsourcers.requestEmailSave(), body);
        if (snapshot) {
          self.notify.success(texts.messages.saved);
        }
        yield self.loadSendEmails();
        return true;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),
    uploadFile: flow(function* (file: File) {
      try {
        const model = new FormData();

        model.append("file", file);
        model.append("accept", ACCEPT);

        const result: UploadFileResult = yield self.transport.post<any>(apiUrls.application.files.upload, model);
        const { id, previewMimeType, mimeType } = result;

        const fileBase: FileBase = { fileId: id, fileName: file.name, previewMimeType, mimeType };
        return fileBase;
      } catch (er) {
        self.notify.error(er);
        return null;
      }
    }),
  }))
  .named("OrderPostStore");

export const initialPostStoreState = (): typeof OrderPostStore.SnapshotType => ({
  list: [],
});

export type OrderPostStoreSnapshotType = typeof OrderPostStore.SnapshotType;
export type OrderPostStoreType = typeof OrderPostStore.Type;

export type OrderPostSnapshotType = typeof OrderPost.SnapshotType;
export type OrderPostType = typeof OrderPost.Type;

const ClientOrder = types
  .compose(
    DictionaryLink,
    types.model({
      actSum: types.number,
      planSum: types.number,
      remainSum: types.number,
      inventoryNumber: types.string,
    })
  )
  .named("ClientOrder");

export const OutsourcerDictionaryLink = types
  .compose(
    DictionaryLink,
    types.model({
      isLegalEntity: types.boolean,
      bankDetails: types.maybeNull(ShortBankDetails),
    })
  )
  .named("OutsourcerDictionaryLink");

const OutsourcerSpending = types
  .model({
    actualSum: types.number,
    actualPayments: types.array(OutsourcerPayment),
    taskAccepted: types.boolean,
    workType: types.maybeNull(WorkType),
    inventoryNumber: types.number,
    fullInventoryNumber: types.string,
    orderName: types.string,
    orderId: types.string,
    debit: types.number,
    credit: types.number,
  })
  .views((self) => ({
    get workTypeName() {
      return self.workType ? self.workType.name : "";
    },
  }))
  .named("OutsourcerSpending");

export const OutsourcerBase = types.compose(
  Transport,
  Notificator,
  BaseEntity,
  types.model({
    phone: types.string,
    phone2: types.string,
    phone3: types.string,
    email: types.string,
    cardNumber: types.string,
    comment: types.string,
    timeRating: types.number,
    priceRating: types.number,
    qualityRating: types.number,
    name: types.string,
    genitiveName: types.string,
    birthday: types.maybeNull(types.string),
    fullName: types.string,
    isConfirmed: types.boolean,
    isBim: types.boolean,
    selfEmployed: types.boolean,
    selfEmployedAccount: types.string,
    selfEmployedAccountDate: types.maybeNull(types.string),
    bankDetails: types.maybeNull(BankDetails),
    workTypes: types.array(DictionaryLink),
    spendings: types.array(OutsourcerSpending),
    passport: Passport,
    login: types.string,
    newPassword: types.optional(types.string, ""),
    type: types.string,
    access: types.array(RoleAccessRecord),
    documents: types.array(SupplierDocument),
    hasSupplierOrder: types.boolean,
    supplierDocuments: types.array(SupplierDocument),
    hasAgentOrder: types.boolean,
    orders: types.maybeNull(types.array(ClientOrder)),
    categories: types.array(types.string),
    materials: types.array(types.string),
    region: types.string,
    site: types.string,
    isAgentReward: types.boolean,
    agentReward: types.number,
    isAgent: types.boolean,
    isLegal: types.boolean,
    fromRequest: types.boolean,
    memberName: types.string,
    materialsList: types.string,
    categoriesList: types.string,
    materialDocuments: types.array(SupplierDocument),
  })
);

export const Outsourcer = OutsourcerBase.views((self) => ({
  get isLegalEntity() {
    return self.type !== "physOutsourcer" && self.isLegal === true;
  },

  get workTypeIds() {
    return self.workTypes.map((type) => type.id);
  },

  get workTypeNames() {
    return self.workTypes.map((type) => type.name);
  },

  get actualSum() {
    return self.spendings.reduce((acc, sp) => acc + sp.actualSum, 0);
  },

  get debit() {
    return self.spendings.reduce((acc, sp) => acc + sp.debit, 0);
  },

  get credit() {
    return self.spendings.reduce((acc, sp) => acc + sp.credit, 0);
  },

  get selfEmployedAccountDateAsDate() {
    return self.selfEmployedAccountDate ? moment(self.selfEmployedAccountDate, DATE_TIME_FORMAT).toDate() : null;
  },

  get birthdayAsDate() {
    return self.birthday ? moment(self.birthday, DATE_TIME_FORMAT).toDate() : null;
  },

  get role(): RoleData {
    return {
      access: getSnapshot(self.access),
      id: self.id,
      label: self.name,
      sublabel: "Аутсорс",
    };
  },
}))
  .actions((self) => ({
    createUrl() {
      return apiUrls.outsourcers.create();
    },

    updateUrl() {
      return apiUrls.outsourcers.update(self.id);
    },
  }))
  .actions((self) => ({
    uploadFile: flow(function* (file: File) {
      try {
        const model = new FormData();

        model.append("file", file);
        model.append("accept", ACCEPT);

        const result: UploadFileResult = yield self.transport.post<any>(apiUrls.application.files.upload, model);
        const { id, previewMimeType, mimeType } = result;

        const fileBase: FileBase = { fileId: id, fileName: file.name, previewMimeType, mimeType };
        return fileBase;
      } catch (er) {
        self.notify.error(er);
        return null;
      }
    }),
    load: flow(function* (id: string, isLegal = true) {
      try {
        const snapshot: any = isNewlyCreated(id)
          ? initialState(isLegal)
          : yield self.transport.get<OutsourcerSnapshotType>(apiUrls.outsourcers.details(id));
        applySnapshot(self, snapshot);

        return true;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),
    loadRequestDetails: flow(function* (id: string, isLegal = true) {
      try {
        const snapshot: any = isNewlyCreated(id)
          ? initialState(isLegal)
          : yield self.transport.get<OutsourcerSnapshotType>(apiUrls.outsourcers.requestDetails(id));
        applySnapshot(self, snapshot);

        return true;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),

    save: flow(function* (model: OutsourcerSnapshotType, isAgentRequestAdd?: boolean) {
      try {
        const body: any = model;

        body["documentIds"] = model.documents ? model.documents.map((d: any) => d.fileId) : [];
        delete body[fields.documents];

        body["supplierDocumentIds"] = model.supplierDocuments ? model.supplierDocuments.map((d: any) => d.fileId) : [];
        delete body[fields.supplierDocuments];

        body["materialDocumentIds"] = model.materialDocuments ? model.materialDocuments.map((d: any) => d.fileId) : [];
        delete body[fields.materialDocuments];

        // convert input string
        if (typeof model.qualityRating === "string") {
          body.qualityRating = parseInt(model.qualityRating, 10);
        }
        if (typeof model.priceRating === "string") {
          body.priceRating = parseInt(model.priceRating, 10);
        }
        // if (!model.selfEmployed && !isLegal) {
        //     body[fields.bankDetails] = null;
        // }
        if (typeof model.timeRating === "string") {
          body.timeRating = parseInt(model.timeRating, 10);
        }
        if (body[fields.selfEmployedAccountDate] instanceof Date) {
          body[fields.selfEmployedAccountDate] = formatDate(body[fields.selfEmployedAccountDate]);
        }
        if (body[fields.passport] && body[fields.passport][passportFields().issueDate] instanceof Date) {
          body[fields.passport][passportFields().issueDate] = formatDate(
            body[fields.passport][passportFields().issueDate]
          );
        }
        if (body[fields.birthday] instanceof Date) {
          body[fields.birthday] = formatDate(body[fields.birthday]);
        }

        if (isAgentRequestAdd) {
          const snapshot: any = yield self.transport.post<OutsourcerSnapshotType>(
            apiUrls.outsourcers.requestAgentAdd(self.id),
            body
          );

          isAlive(self) && applySnapshot(self, snapshot);
          self.notify.success(texts.messages.saved);
          return true;
        }
        const snapshot: any = self.isNewlyCreated
          ? yield self.transport.put<OutsourcerSnapshotType>(self.createUrl(), body)
          : yield self.transport.post<OutsourcerSnapshotType>(self.updateUrl(), body);

        isAlive(self) && applySnapshot(self, snapshot);
        self.notify.success(texts.messages.saved);

        return true;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),

    delete: flow(function* (isAgentRequest?: boolean) {
      if (self.isNewlyCreated) {
        return;
      }

      try {
        if (isAgentRequest) {
          yield self.transport.delete<boolean>(apiUrls.outsourcers.removeRequestAgent(self.id));
          self.notify.success(texts.messages.removed);
          applySnapshot(self, initialState(isLegalEntity(self)));
          return true;
        }

        yield self.transport.delete<boolean>(apiUrls.outsourcers.delete(self.id));
        self.notify.success(texts.messages.removed);

        applySnapshot(self, initialState(isLegalEntity(self)));

        return true;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),

    format() {
      return formatOutsourcer(self);
    },

    getCredentials: flow(function* () {
      try {
        const result: OutsourcerCredentials = yield self.transport.get<any>(apiUrls.outsourcers.credentials(self.id));
        return result;
      } catch (er) {
        self.notify.error(er);
        return null;
      }
    }),

    setFunctionality: flow(function* (functionality: string, state: boolean) {
      try {
        const data: any = yield self.transport.post<any>(apiUrls.outsourcers.singleAccess(self.id), {
          functionality,
          state,
        });

        applySnapshot(self.access, data);

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .named("Outsourcer");

export type OutsourcerSnapshotType = typeof OutsourcerBase.SnapshotType;
export type OutsourcerType = typeof Outsourcer.Type;

export const fields = {
  name: nameof((a: OutsourcerType) => a.name) as string,
  fullName: nameof((a: OutsourcerType) => a.fullName) as string,
  genitiveName: nameof((a: OutsourcerType) => a.genitiveName) as string,
  birthday: nameof((a: OutsourcerType) => a.birthday) as string,
  phone: nameof((a: OutsourcerType) => a.phone) as string,
  phone2: nameof((a: OutsourcerType) => a.phone2) as string,
  phone3: nameof((a: OutsourcerType) => a.phone3) as string,
  email: nameof((a: OutsourcerType) => a.email) as string,
  cardNumber: nameof((a: OutsourcerType) => a.cardNumber) as string,
  comment: nameof((a: OutsourcerType) => a.comment) as string,
  timeRating: nameof((a: OutsourcerType) => a.timeRating) as string,
  priceRating: nameof((a: OutsourcerType) => a.priceRating) as string,
  qualityRating: nameof((a: OutsourcerType) => a.qualityRating) as string,
  bankDetails: nameof((a: OutsourcerType) => a.bankDetails) as string,
  isLegalEntity: nameof((a: OutsourcerType) => a.isLegalEntity) as string,
  workTypeIds: nameof((a: OutsourcerType) => a.workTypeIds) as string,
  isConfirmed: nameof((a: OutsourcerType) => a.isConfirmed) as string,
  isBim: nameof((a: OutsourcerType) => a.isBim) as string,
  selfEmployed: nameof((a: OutsourcerType) => a.selfEmployed) as string,
  selfEmployedAccount: nameof((a: OutsourcerType) => a.selfEmployedAccount) as string,
  selfEmployedAccountDate: nameof((a: OutsourcerType) => a.selfEmployedAccountDate) as string,
  actualSum: nameof((a: OutsourcerType) => a.actualSum) as string,
  debit: nameof((a: OutsourcerType) => a.debit) as string,
  credit: nameof((a: OutsourcerType) => a.credit) as string,
  passport: nameof((a: OutsourcerType) => a.passport) as string,
  login: nameof((a: OutsourcerType) => a.login) as string,
  newPassword: nameof((a: OutsourcerType) => a.newPassword) as string,
  type: nameof((a: OutsourcerType) => a.type) as string,
  hasAgentOrder: nameof((a: OutsourcerType) => a.hasAgentOrder) as string,
  hasSupplierOrder: nameof((a: OutsourcerType) => a.hasSupplierOrder) as string,
  documents: nameof((a: OutsourcerType) => a.documents) as string,
  supplierDocuments: nameof((a: OutsourcerType) => a.supplierDocuments) as string,
  сategories: nameof((a: OutsourcerType) => a.categories) as string,
  materials: nameof((a: OutsourcerType) => a.materials) as string,
  region: nameof((a: OutsourcerType) => a.region) as string,
  site: nameof((a: OutsourcerType) => a.site) as string,
  isAgentReward: nameof((a: OutsourcerType) => a.isAgentReward) as string,
  isAgent: nameof((a: OutsourcerType) => a.isAgent) as string,
  isLegal: nameof((a: OutsourcerType) => a.isLegal) as string,
  fromRequest: nameof((a: OutsourcerType) => a.fromRequest) as string,
  agentReward: nameof((a: OutsourcerType) => a.agentReward) as string,
  memberName: nameof((a: OutsourcerType) => a.memberName) as string,
  materialDocuments: nameof((a: OutsourcerType) => a.materialDocuments) as string,
  materialsList: nameof((a: OutsourcerType) => a.materialsList) as string,
  categoriesList: nameof((a: OutsourcerType) => a.categoriesList) as string,
  ...bankDetailsFields(`${nameof((a: OutsourcerType) => a.bankDetails) as string}.`),
  ...passportFields(`${nameof((a: OutsourcerType) => a.passport)}.`),
};

export const initialState = (isLegal: boolean, id = EMPTY_OBJECT_ID): OutsourcerSnapshotType => ({
  id,
  cardNumber: "",
  comment: "",
  created: moment().format(DATE_TIME_FORMAT),
  email: "",
  name: "",
  fullName: "",
  genitiveName: "",
  birthday: null,
  phone: "",
  phone2: "",
  phone3: "",
  qualityRating: 3,
  timeRating: 3,
  priceRating: 3,
  isConfirmed: false,
  isBim: false,
  selfEmployed: false,
  selfEmployedAccount: "",
  selfEmployedAccountDate: null,
  bankDetails: isLegal ? bankDetailsInitialState() : null,
  workTypes: [],
  spendings: [],
  passport: emptyPassport(),
  login: "",
  newPassword: "",
  access: [],
  type: "legalOutsourcer",
  hasAgentOrder: false,
  documents: [],
  hasSupplierOrder: false,
  supplierDocuments: [],
  orders: null,
  categories: [],
  materials: [],
  region: "",
  site: "",
  isAgentReward: false,
  agentReward: 0,
  isAgent: false,
  isLegal: true,
  fromRequest: false,
  memberName: "",
  materialDocuments: [],
  materialsList: "",
  categoriesList: "",
});

export function isLegalEntity(outsourcer: OutsourcerType | OutsourcerSnapshotType): boolean {
  return outsourcer.bankDetails !== null && !outsourcer.selfEmployed;
}

export function workTypeNames(outsourcer: OutsourcerType | OutsourcerSnapshotType): string {
  const names: string[] = [];

  for (let wt of outsourcer.workTypes) {
    names.push(wt.name);
  }

  return names.sort().join(", ");
}

export function formatOutsourcer(outsourcer: OutsourcerSnapshotType | null) {
  let result = "";

  if (outsourcer) {
    const schema = Schema(true);
    const isLegal = isLegalEntity(outsourcer);

    result += `${isLegal ? LegalNameLabel : PhysycalNameLabel}: ${outsourcer.name}\n`;
    if (isLegal) {
      result += `${getFieldLabel(fields.fullName, schema, null)}: ${outsourcer.fullName}\n`;
    }
    result += `${getFieldLabel(fields.phone, schema, null)}: ${formatPhoneNumber(outsourcer.phone)}\n`;
    if (outsourcer.phone2) {
      result += `${getFieldLabel(fields.phone2, schema, null)}: ${formatPhoneNumber(outsourcer.phone2)}\n`;
    }
    if (outsourcer.phone3) {
      result += `${getFieldLabel(fields.phone3, schema, null)}: ${formatPhoneNumber(outsourcer.phone3)}\n`;
    }
    result += `${getFieldLabel(fields.email, schema, null)}: ${outsourcer.email}\n`;
    result += `${getFieldLabel(fields.comment, schema, null)}: ${outsourcer.comment}\n`;
    if (!isLegal) {
      result += `${getFieldLabel(fields.cardNumber, schema, null)}: ${outsourcer.cardNumber}\n`;
    }
    result += formatBankDetails(outsourcer.bankDetails);
  }

  return result;
}

export interface OutsourcerCredentials {
  login: string;
  password: string;
}
