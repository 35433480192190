import { Classes, Dialog, Tab, Tabs } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { OutsourcerDetails } from "modules/agents/outsourcers/components/details/OutsourcerDetails";
import { Page } from "modules/common/components/page/Page";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanrLink } from "modules/common/components/planr/link/Link";
import { routes } from "modules/common/routes";
import { eat } from "modules/common/services/typescript";
import { texts } from "modules/common/texts";
import { OwnFinanceRequests } from "modules/expenses/finance-requests/components/OwnFinanceRequests";
import orderFunc, { canReadOrder } from "modules/orders-manage/functionalities";
import { ApplicationStoreType } from "modules/root/models/store";
import { can } from "modules/session/auth/access";
import React from "react";
import { RouteComponentProps } from "react-router";
import func from "../functionalities";
import styles from "./MainAppPage.module.scss";
import { Calendar } from "./calendar/Calendar";
import { Dashboard } from "./dashboard/Dashboard";
import { MessagesWidget } from "./messages/MessagesWidget";
import { NewsWidget } from "./news/NewsWidget";
import { OwnMaterialsWidget } from "./own-materials/OwnMaterialsWidget";
import { OwnPremiumsWidget } from "./own-premiums/OwnPremiumsWidget";
import { OwnSpendingsWidget } from "./own-spendings/OwnSpendingsWidget";
import { SurveyWidget } from "./surveys/SurveyWidget";
import { tabs } from "./tabsMainpage";
import { DefaultSelectedOption, SimpleSelect, renderSingleOption } from "modules/common/services/form/select";

class MainAppPage extends React.PureComponent<MainAppPageProps & RouteComponentProps, MainAppPageState> {
  constructor(props: MainAppPageProps & RouteComponentProps) {
    super(props);

    this.state = {
      employeeIsOpen: false,
      ownSpendingsIsOpen: false,
      materialsIsOpen: false,
      newsIsOpen: false,
      messagesIsOpen: false,
      premiumsIsOpen: false,
      personalDataIsOpen: false,
      agentDataIsOpen: false,
      taskIsOpen: false,
      isMobile: false,
    };
  }
  updateFile = async () => {
    await this.props.root.session.updateFile();
  };
  handleResize = () => {
    if (window.innerWidth < 885) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentWillMount() {
    this.handleResize();
  }
  componentDidMount() {
    document.title = "Главная";
    window.addEventListener("resize", this.handleResize);

    const { session, employeeWidget, ownSpendingsWidget } = this.props.root;
    const tabsVisible = can(func.INTERNAL_USER_ACCESS, session.access);

    if (tabsVisible) {
      employeeWidget.list.load();
      employeeWidget.list.loadProjects(session.userId);
      ownSpendingsWidget.load(session.userId, false);
      session.loadPremiums();
    }

    if (session.outsourcer !== null) {
      session.outsourcer.load();
    }

    if (session.agent !== null) {
      session.agent.load();
    }

    this.updateFile();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  Refresh = () => {
    const { dashboard } = this.props.root;
    dashboard.loadcharts();
  };
  employeeHandleOpen = () => this.setState({ employeeIsOpen: true });
  employeeHandleClose = () => this.setState({ employeeIsOpen: false });

  materialsHandleOpen = () => this.setState({ materialsIsOpen: true });
  materialsHandleClose = () => this.setState({ materialsIsOpen: false });

  messagesHandleOpen = () => this.setState({ messagesIsOpen: true });
  messagesHandleClose = () => this.setState({ messagesIsOpen: false });

  taskHandleOpen = () => this.setState({ taskIsOpen: true });
  taskHandleClose = () => this.setState({ taskIsOpen: false });

  newsHandleOpen = () => {
    const { newsWidget } = this.props.root;
    newsWidget.loadPage();

    return this.setState({ newsIsOpen: true });
  };
  newsHandleClose = () => this.setState({ newsIsOpen: false });

  ownSpendingsHandleOpen = () => this.setState({ ownSpendingsIsOpen: true });
  ownSpendingsHandleClose = () => this.setState({ ownSpendingsIsOpen: false });

  showPremiums = () => this.setState({ premiumsIsOpen: true });
  hidePremiums = () => this.setState({ premiumsIsOpen: false });

  handleTabChange = (newTabId: string) => {
    const route = routes.main.path + newTabId;
    this.props.history.push(route);
  };

  showPersonalData = () => this.setState({ personalDataIsOpen: true });
  hidePersonalData = () => this.setState({ personalDataIsOpen: false });

  showAgentData = () => this.setState({ agentDataIsOpen: true });
  hideAgentData = () => this.setState({ agentDataIsOpen: false });

  render() {
    const { session } = this.props.root;
    const tabsVisible = can(func.INTERNAL_USER_ACCESS, session.access);

    return <Page>{tabsVisible ? this.renderInternalUserTasbs() : this.renderExternalUserTabs()}</Page>;
  }

  renderExternalUserTabs = () => {
    const { location, root, baseUrl } = this.props;
    const { session, detailedOrders, dashboard, moneyAccounts } = root;
    const { access, outsourcer, agent } = session;
    const { personalDataIsOpen } = this.state;
    const tabId = location.hash.includes(tabs.charts) ? tabs.charts : tabs.main;
    const chartVisible = can(orderFunc.FLOW_ORDERS_CHART, access);
    const canRefresh = can(orderFunc.FLOW_ORDERS_CHART_REFRESH, access);

    let tabContent = null;
    if (outsourcer) {
      tabContent =
        personalDataIsOpen && outsourcer ? (
          <OutsourcerDetails
            access={access}
            canChange={true}
            canRemove={false}
            canConfirm={false}
            canSupplierOrder={false}
            workTypes={outsourcer.workTypes}
            outsourcer={outsourcer.self}
            categories={outsourcer.categories}
            onSaved={eat}
            onRemoved={eat}
            onPrintCredentials={eat}
            showSensitiveData={false}
            baseUrl={baseUrl}
          >
            <PlanrButton
              type="primary"
              onClick={this.hidePersonalData}
              style={{ marginRight: "auto", marginTop: "1rem" }}
              size="small"
            >
              {texts.close}
            </PlanrButton>
          </OutsourcerDetails>
        ) : (
          <PlanrButton
            type="secondary"
            onClick={this.showPersonalData}
            style={{ marginTop: "30px" }}
            icon="general-users"
          >
            Изменить персональные данные
          </PlanrButton>
        );
    }

    if (agent) {
      tabContent =
        personalDataIsOpen && agent ? (
          <OutsourcerDetails
            access={access}
            canChange={true}
            canRemove={false}
            canConfirm={false}
            canSupplierOrder={false}
            workTypes={agent.workTypes}
            outsourcer={agent.self}
            categories={agent.categories}
            onSaved={eat}
            onRemoved={eat}
            onPrintCredentials={eat}
            showSensitiveData={false}
            baseUrl={baseUrl}
          >
            <PlanrButton
              type="primary"
              onClick={this.hidePersonalData}
              style={{ marginRight: "auto", marginTop: "1rem" }}
              size="small"
            >
              {texts.close}
            </PlanrButton>
          </OutsourcerDetails>
        ) : (
          <PlanrButton
            type="secondary"
            onClick={this.showPersonalData}
            style={{ marginTop: "30px" }}
            icon="general-users"
          >
            Изменить персональные данные
          </PlanrButton>
        );
    }

    return (
      <Tabs
        className={`${styles.tabs} planr-tabs-navigation`}
        id="ExternalTabs"
        selectedTabId={tabId}
        onChange={this.handleTabChange}
        renderActiveTabPanelOnly={true}
      >
        <Tab
          id={tabs.main}
          title="Главная"
          panel={<div className={`${styles.page} ${styles.compact}`}>{tabContent}</div>}
        />
        {chartVisible && (
          <Tab
            id={tabs.charts}
            title={"Дашборд"}
            panel={
              <>
                <Dashboard
                  table={detailedOrders}
                  dashboard={dashboard}
                  accounts={moneyAccounts}
                  onRefresh={canRefresh ? this.Refresh : undefined}
                />
              </>
            }
          />
        )}
      </Tabs>
    );
  };

  renderEmptyTabs = () => {
    return (
      <Tabs
        className={`${styles.tabs} planr-tabs-navigation`}
        id="HelloTabs"
        selectedTabId="1"
        renderActiveTabPanelOnly={true}
      >
        <Tab
          id="1"
          title="Главная"
          panel={
            <div className={styles.page}>
              <h2>Рады приветствовать Вас!</h2>
              <h2>
                Пожалуйста введите все необходимые данные о поставляемом оборудовании и материалах (предоставляемых
                услугах) для возможности включения в проекты нашей организации.
              </h2>
            </div>
          }
        />
      </Tabs>
    );
  };

  renderInternalUserTasbs = () => {
    const { location, history, match } = this.props;
    const {
      newsWidget,
      detailedOrders,
      dashboard,
      moneyAccounts,
      session,
      surveysWidget,
      eventsWidget,
      messagesWidget,
      ownSpendingsWidget,
      inventoryItems,
      employeeWidget,
      ownFinanceRequests,
      orderContentTaskList,
      suppliers,
      notifications,
      baseUrl,
    } = this.props.root;

    const orderAccess = canReadOrder(session.access);
    const chartVisible = can(orderFunc.FLOW_ORDERS_CHART, session.access);
    let items = [
      { id: tabs.main, label: "Главная" },
      { id: tabs.charts, label: "Дашборд" },
    ];

    if (!chartVisible) {
      const index = items.findIndex((i) => i.id === tabs.charts);
      if (index > -1) {
        items.splice(index, 1);
      }
    }
    const tabId = location.hash.includes(tabs.charts) ? tabs.charts : tabs.main;
    const canComment = can(orderFunc.TIMESHEET_COMMENTS, session.access);
    const selectedTab = items.find((i) => i.id === tabId);
    const style: React.CSSProperties = {
      marginLeft: this.state.isMobile ? "0px" : "8px",
    };

    return (
      <Tabs
        className={`${styles.tabs} planr-tabs-navigation-mobile`}
        id="MainAppPageTabs"
        selectedTabId={tabId}
        onChange={this.handleTabChange}
        renderActiveTabPanelOnly={true}
      >
        {this.state.isMobile && (
          <SimpleSelect
            className={`full-width-select ${Classes.FILL}`}
            activeItem={selectedTab}
            items={items}
            itemRenderer={renderSingleOption}
            onItemSelect={(i: SelectItem) => {
              const route = routes.main.path + i.id;
              this.props.history.push(route);
            }}
            filterable={false}
            popoverProps={{
              boundary: "viewport",
              //  usePortal: true,
              // popoverClassName: "order-content_mentor-select-dropdown",
            }}
          >
            <DefaultSelectedOption option={selectedTab} />
          </SimpleSelect>
        )}

        <Tab
          id={tabs.main}
          title="Главная"
          panel={
            <div className={styles.page}>
              <div className={styles.left}>
                <div className={styles.left_topbtns}>
                  {session.manualFile && (
                    // <PlanrButton
                    //   type="secondary"
                    //   size="small"
                    //   onClick={() => {
                    //     window.open(session.fileLink, "_blank");
                    //   }}
                    // >
                    //   Долж. инструкция
                    // </PlanrButton>
                    <PlanrLink type="secondary" size="small" target="_blank" href={session.fileLink}>
                      Долж. инструкция
                    </PlanrLink>
                  )}
                  {!session.manualFile && (
                    <PlanrButton type="secondary" size="small">
                      Долж. инструкция
                    </PlanrButton>
                  )}
                  <PlanrButton type="secondary" size="small" onClick={this.materialsHandleOpen} style={style}>
                    Инвентарь
                  </PlanrButton>
                  <PlanrButton type="secondary" size="small" onClick={this.ownSpendingsHandleOpen} style={style}>
                    Участие в проектах
                  </PlanrButton>
                  <PlanrButton type="secondary" size="small" onClick={this.showPremiums} style={style}>
                    Назначенные премии
                  </PlanrButton>
                  <OwnFinanceRequests store={ownFinanceRequests} suppliers={suppliers} isMobile={this.state.isMobile} />
                </div>

                <Calendar
                  calendar={eventsWidget}
                  session={session}
                  canComment={canComment}
                  orderContentTaskList={orderContentTaskList}
                  notification={notifications}
                  baseUrl={baseUrl}
                />

                <Dialog
                  title="Участие в проектах"
                  canEscapeKeyClose={true}
                  canOutsideClickClose={true}
                  isOpen={this.state.ownSpendingsIsOpen}
                  onClose={this.ownSpendingsHandleClose}
                  style={{
                    width: this.state.isMobile ? `100%` : `580px`,
                    height: "460px",
                  }}
                  backdropClassName="standard"
                  className={`figma-dialog`}
                >
                  <div
                    className={`${Classes.DIALOG_BODY} ${styles.dialog}`}
                    style={{ marginLeft: "8px", marginRight: "8px" }}
                  >
                    <OwnSpendingsWidget
                      store={ownSpendingsWidget}
                      withSpendings={true}
                      employeeList={employeeWidget.list}
                      employerId={session.userId}
                    />
                  </div>
                </Dialog>

                <Dialog
                  icon="info-sign"
                  title="Закрепленный инвентарь"
                  canEscapeKeyClose={true}
                  canOutsideClickClose={true}
                  isOpen={this.state.materialsIsOpen}
                  onClose={this.materialsHandleClose}
                  style={{ width: this.state.isMobile ? `100%` : `600px` }}
                  backdropClassName="standard"
                >
                  <div className={Classes.DIALOG_BODY}>
                    <OwnMaterialsWidget store={inventoryItems.list} employerId={session.userId} />
                  </div>
                </Dialog>

                <Dialog
                  icon="info-sign"
                  title="Назначенные премии"
                  canEscapeKeyClose={true}
                  canOutsideClickClose={true}
                  isOpen={this.state.premiumsIsOpen}
                  onClose={this.hidePremiums}
                  style={{ width: this.state.isMobile ? `100%` : `600px` }}
                  backdropClassName="standard"
                >
                  <div className={Classes.DIALOG_BODY}>
                    <OwnPremiumsWidget store={session.premiums} orderAccess={orderAccess} />
                  </div>
                </Dialog>
              </div>
              <div className={styles.right}>
                <Dialog
                  title=""
                  canEscapeKeyClose={true}
                  canOutsideClickClose={true}
                  isOpen={this.state.newsIsOpen}
                  onClose={this.newsHandleClose}
                  style={{
                    width: `1200px`,
                    height: "900px",
                  }}
                  backdropClassName="standard"
                  className={`figma-dialog`}
                >
                  <div className={`${Classes.DIALOG_BODY} ${styles.dialog}`}>
                    <NewsWidget
                      store={newsWidget}
                      session={session}
                      isDialog={true}
                      history={history}
                      location={location}
                      match={match}
                    />
                  </div>
                </Dialog>
                <Dialog
                  title=""
                  canEscapeKeyClose={true}
                  canOutsideClickClose={true}
                  isOpen={this.state.messagesIsOpen}
                  onClose={this.messagesHandleClose}
                  style={{
                    width: `1200px`,
                    height: "900px",
                  }}
                  backdropClassName="standard"
                  className={`figma-dialog`}
                >
                  <div className={`${Classes.DIALOG_BODY} ${styles.dialog}`}>
                    <MessagesWidget store={messagesWidget} isDialog={true} isMobile={this.state.isMobile} />
                  </div>
                </Dialog>
                <SurveyWidget store={surveysWidget} session={session} />
                <NewsWidget
                  store={newsWidget}
                  session={session}
                  onOpen={this.newsHandleOpen}
                  history={history}
                  location={location}
                  match={match}
                />
                <MessagesWidget store={messagesWidget} onOpen={this.messagesHandleOpen} />
              </div>
            </div>
          }
        />
        {chartVisible && (
          <Tab
            id={tabs.charts}
            title={"Дашборд"}
            panel={
              <>
                <Dashboard
                  table={detailedOrders}
                  dashboard={dashboard}
                  accounts={moneyAccounts}
                  onRefresh={this.Refresh}
                />
              </>
            }
          />
        )}
      </Tabs>
    );
  };
}
export default observer(MainAppPage);

interface MainAppPageProps {
  root: ApplicationStoreType;
  baseUrl: string;
}

interface MainAppPageState {
  personalDataIsOpen: boolean;
  agentDataIsOpen: boolean;
  taskIsOpen: boolean;
  employeeIsOpen: boolean;
  ownSpendingsIsOpen: boolean;
  materialsIsOpen: boolean;
  newsIsOpen: boolean;
  messagesIsOpen: boolean;
  premiumsIsOpen: boolean;
  isMobile: boolean;
}
