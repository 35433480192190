import saveAs from "file-saver";
import { groupBy, sortBy, sumBy } from "lodash";
import { applySnapshot, cast, getParent, isAlive, types } from "mobx-state-tree";
import { emptyOutsourcerPayment } from "modules/agents/outsourcers/models/outsourcer-payment";
import { formatMoney } from "modules/common/components/money/Money";
import { DATE_TIME_FORMAT, EMPTY_OBJECT_ID, SORTABLE_DATE_TIME_FORMAT, WHITE } from "modules/common/constants";
import { DictionaryLink, initialState as emptyLink } from "modules/common/models/dictionary-link";
import { BaseEntity } from "modules/common/models/entity";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Ordered } from "modules/common/models/ordered";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { MimeTypes, base64ToBlob } from "modules/common/services/files";
import { formatDate } from "modules/common/services/formatting/date";
import { trimEnd, trimStart } from "modules/common/services/strings";
import { nameof } from "modules/common/services/typescript";
import { IpdTypeDictionaryItemType } from "modules/dictionaries/ipd-types/models/ipd-type";
import { WorkTypeDictionaryItemSnapshotType } from "modules/dictionaries/work-types/models/work-type";
import func from "modules/orders-manage/functionalities";
import { Constants } from "modules/root/models/constants";
import { can } from "modules/session/auth/access";
import moment from "moment";
import { parse } from "query-string";
import { v4 } from "uuid";
import {
  OrderIndicatorDictionaryItem,
  OrderIndicatorMarkupSnapshotType,
} from "./../../dictionaries/order-indicators/models/order-indicator";
import { Agent, AgentPayment, AgentType, OrderAgentSnapshotType, SaveAgent } from "./order-agents";
import { OrderBase } from "./order-base";
import { OrderCommunication, OrderCommunicationRowType, initialOrderCommunication } from "./order-communication";
import { emptyCompletion } from "./order-completions";
import { OrderContentTasks } from "./order-content-tasks";
import { OrderExpertise } from "./order-expertise";
import {
  ClientFeedbackFile,
  ClientFeedbackFileType,
  ExpertiseFile,
  OrderFile,
  // emptyConsignment,
  // consignmentFileFields,
  OrderFileSnapshotType,
  SentExpertiseFile,
  IpdFile as StampFile,
} from "./order-file";
import { FinanceValues, OrderFinances, initialFinances as emptyFinanceValues } from "./order-finance-values";
import { OrderIpd, SaveOrderIpd, ipdFields } from "./order-ipd";
import {
  OrderTimesheetSpending,
  OtherOrderSpending,
  OtherOrderSpendingSnapshotType,
  OutsourcedOrderSpending,
  OutsourcedOrderSpendingSnapshotType,
  OwnOrderSpending,
  OwnOrderSpendingSnapshotType,
  TripOrderSpending,
  TripOrderSpendingSnapshotType,
  outsourcedSpendingFields,
  tripSpendingFields,
} from "./order-spending";
import {
  OrderStageRow,
  OrderStageRowItem,
  OrderStageType,
  PlanOrderPaymentDetailsRow,
  StageItem,
  treatPayments,
  treatStages,
} from "./order-stages-store";
import { OrderTechIndicator, SaveOrderTechIndicator } from "./order-tech-indicator";
import { OrderPaymentSnapshotType, paymentFields } from "./payment";
import { OrderPremiumBank } from "./premium";

export function fullInventoryNumber(inventoryNumber: number | string) {
  return inventoryNumber === "" ? "" : inventoryNumber.toString().padStart(2, "0");
}

export const WORK_CODE_SEPARATOR = " - ";

const ContentWorkMentor = types
  .model({
    id: types.string,
    name: types.string,
    position: types.string,
    login: types.string,
  })

  .named("ContentWorkMentor");
export const AcceptUnitFile = [MimeTypes.jpeg, MimeTypes.png, MimeTypes.pdf].join(",");
export const OrderContentWorkTypeLink = types
  .compose(
    Ordered,
    types.model({
      workTypeId: types.string,
      name: types.string,
      description: types.string,
      outsourced: types.boolean,
      workTypeCategory: types.string,
      guid: types.string,
      sortOrder: types.number,
      lastModified: types.maybeNull(types.string),
      progress: types.number,
      orderNumber: types.string,
      partNumber: types.string,
      mentor: types.maybeNull(ContentWorkMentor),
      defaultMentor: types.maybeNull(ContentWorkMentor),
      mentorId: types.maybeNull(types.string),
      stampFile: types.maybeNull(StampFile),
    })
  )

  .named("WorkTypeLink");

export const OrderObject = types
  .compose(
    Transport,
    types
      .model({
        guid: types.string,
        name: types.string,
        inventoryNumber: types.number,
        content: types.array(OrderContentWorkTypeLink),
      })
      .views((self) => ({
        get fullInventoryNumber() {
          return fullInventoryNumber(self.inventoryNumber);
        },

        get contentMap() {
          return groupBy(self.content, (c) => c.workTypeCategory);
        },

        /** Нет привзяки к объекту на генплане */
        get isCommon() {
          return objectIsCommon(self);
        },
      }))
  )
  .named("OrderObject");

/** Нет привзяки к объекту на генплане */
export const objectIsCommon = (self: OrderObjectSnapshotType) => self.inventoryNumber === 0;

/**
 * show only filled rows and default row
 * default row should be first
 */
export const sortOrderObjects = (value: OrderObjectSnapshotType[]) =>
  sortBy(
    value.filter((o) => !!o.name || objectIsCommon(o)),
    (obj, index) => (objectIsCommon(obj) ? -1 : index)
  );

export const getObjectsToDisplay = (category: string, objects: OrderObjectSnapshotType[]) =>
  !!category ? objects : objects.filter(objectIsCommon);

export const filterObjectUnits = (object: OrderObjectSnapshotType, category: string) =>
  object.content.filter((unit) => unit.workTypeCategory === category);

export const getCategoryProgress = (category: string, objects: OrderObjectSnapshotType[]) => {
  const categoryUnits = objects.reduce((acc, object) => {
    return [...acc, ...filterObjectUnits(object, category)];
  }, [] as WorkTypeLinkSnapshotType[]);

  return categoryUnits.length > 0 ? sumBy(categoryUnits, (o) => +o.progress) / categoryUnits.length : 0;
};

export function getOrderPrice(stages: OrderStageType[]) {
  return stages.reduce((acc, est) => +est.sum + acc, 0);
}

const OrderSummaryBase = types
  .compose(
    OrderBase,
    Transport,
    Notificator,
    BaseEntity,

    types.model({
      isInternal: types.boolean,
      name: types.string,
      hidePrice: types.boolean,
      stopDateActual: types.maybeNull(types.string),
      stopDatePlan: types.maybeNull(types.string),
      stopDayPlan: types.maybeNull(types.string),
      stopDatePlanWarning: types.maybeNull(types.string),
      fullName: types.string,
      inventoryNumber: types.number,
      fullInventoryNumber: types.string,
      color: types.string,
      cypher: types.string,
      municipalContract: types.boolean,
      batchContract: types.boolean,
      regionContract: types.boolean,
      federalContract: types.boolean,
      projectPortfolio: types.maybeNull(DictionaryLink),
      orderStatusLink: types.maybeNull(DictionaryLink),
      client: types.maybeNull(DictionaryLink),
      orderType: types.maybeNull(DictionaryLink),
      productionStage: types.maybeNull(DictionaryLink),
      employer: types.maybeNull(DictionaryLink),
      objects: types.array(OrderObject),
      ownOrderSpendings: types.array(OwnOrderSpending),
      outsourcedOrderSpendins: types.array(OutsourcedOrderSpending),
      otherOrderSpendings: types.array(OtherOrderSpending),
      tripOrderSpendings: types.array(TripOrderSpending),
      timesheetSpendings: OrderTimesheetSpending,
      financeStages: types.array(OrderStageRow),
      planPayments: types.array(PlanOrderPaymentDetailsRow),
      otherOrders: types.array(OrderFile),
      additionalOrders: types.array(OrderFile),
      expertises: types.maybeNull(types.array(OrderExpertise)),
      premiumPercents: types.number,
      premiumGipPercents: types.number,
      premiumProductionPercents: types.number,
      premiumNonProductionPercents: types.number,
      progress: types.number,
      ipds: types.array(OrderIpd),
      techIndicators: types.array(OrderTechIndicator),
      orderCommunication: OrderCommunication,
      contentTasks: types.array(OrderContentTasks),
      financeValues: OrderFinances,
      planProductionSpendingsPercents: types.number,
      feedbackFile: types.maybeNull(ClientFeedbackFile),
      premiumBank: OrderPremiumBank,
      employeeFeedback: types.map(types.boolean),
      finValuesActualOverheadPercents: types.maybeNull(types.number),
      finValuesPlanOverheadPercents: types.maybeNull(types.number),
      wopiToken: types.string,
      editorUrl: types.string,
      canCreateTaskVersion: types.boolean,
      canClientDownloadTasks: types.boolean,
      price: types.number,
      stages: types.array(StageItem),
      orderAgents: types.array(Agent),
      orderAgentPayments: types.array(AgentPayment),
      indicators: types.array(OrderIndicatorDictionaryItem),
      isConfirmed: types.boolean,
      isGip: types.boolean,
    })
  )
  .views((self) => ({
    get stageItems() {
      return self.financeStages.map((stage, index) => ({ stage, index } as OrderStageRowItem));
    },

    get projectPortfolioId() {
      return self.projectPortfolio ? self.projectPortfolio.id : "";
    },
    get orderStatusId() {
      return self.orderStatusLink ? self.orderStatusLink.id : "";
    },
    get clientId() {
      return self.client ? self.client.id : "";
    },
    get orderTypeId() {
      return self.orderType ? self.orderType.id : "";
    },
    get productionStageId() {
      return self.productionStage ? self.productionStage.id : "";
    },
    get engineerId() {
      return self.employer ? self.employer.id : "";
    },
    get feedbackFileId() {
      return self.feedbackFile ? self.feedbackFile.fileId : "";
    },
    get startDateAsDate() {
      return self.startDate ? moment(self.startDate, DATE_TIME_FORMAT).toDate() : null;
    },
    get stopDateActualAsDate() {
      return self.stopDateActual ? moment(self.stopDateActual, DATE_TIME_FORMAT).toDate() : null;
    },
    get stopDatePlanAsDate() {
      return self.stopDatePlan ? moment(self.stopDatePlan, DATE_TIME_FORMAT).toDate() : null;
    },
    get orderTitle() {
      return self.isNewlyCreated ? "Новый договор" : orderTitle(self);
    },
    get premiumDistribution() {
      const bank = self.premiumBank.calculatedSumm;
      const correct = self.premiumBank.finalSum;

      return {
        premiumGip: {
          calculatedSumm: (bank / 100) * self.premiumGipPercents,
          finalSum: (correct / 100) * self.premiumGipPercents,
        },
        premiumProduction: {
          calculatedSumm: (bank / 100) * self.premiumProductionPercents,
          finalSum: (correct / 100) * self.premiumProductionPercents,
        },
        premiumNonProduction: {
          calculatedSumm: (bank / 100) * self.premiumNonProductionPercents,
          finalSum: (correct / 100) * self.premiumNonProductionPercents,
        },
      };
    },
  }))
  .actions((self) => ({
    loadStages: flow(function* (id: string) {
      try {
        const stages = yield self.transport.get<any>(apiUrls.orders.stages.listIpd(id));
        if (stages) {
          const newArr = stages.map((st: any) => ({ guid: st.guid, name: st.name }));
          applySnapshot(self.stages, newArr);
        }
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    loadOrderStages: flow(function* (id: string) {
      try {
        if (!self.isNewlyCreated) {
          const stages = yield self.transport.get<any>(apiUrls.orders.stages.list(id));
          applySnapshot(self.financeStages, treatStages(stages));
          const payments = treatPayments(yield self.transport.get<any>(apiUrls.orders.stages.payments.list(id)));
          applySnapshot(self.planPayments, payments.plan);
        }

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    updateStages(stages: any) {
      const newArr = stages.map((st: any) => ({ guid: st.guid, name: st.name }));
      self.stages = newArr;
    },

    loadOrderIndicators: flow(function* (id: string) {
      try {
        const data = yield self.transport.get<OrderIndicatorMarkupSnapshotType[]>(apiUrls.orderIndicators.list());
        if (data) {
          applySnapshot(self.indicators, data);
        }
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    loadOrderAgents: flow(function* (id: string) {
      try {
        const data = yield self.transport.get<OrderAgentSnapshotType>(apiUrls.orders.orderAgents.list(id));
        console.info(data);
        if (data && data.id != null) {
          applySnapshot(self.orderAgents, data.agents);
          applySnapshot(self.orderAgentPayments, data.agentPayments != null ? data.agentPayments : []);
        }
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    downloadFile: flow(function* (files: string[]) {
      try {
        const content: DownloadFileResult = yield self.transport.post<any>(apiUrls.application.files.download, {
          ids: files,
        });

        if (content) {
          const blob: any = yield base64ToBlob(content.content, content.mimeType);
          saveAs(blob, content.name);
          return true;
        }
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    uploadFile: flow(function* (file: File) {
      try {
        const model = new FormData();

        model.append("file", file);
        model.append("accept", "*");
        const result: UploadFileResult = yield self.transport.post<any>(apiUrls.application.files.upload, model);
        const { id, previewMimeType, mimeType } = result;

        const fileBase: FileBase = { fileId: id, fileName: file.name, previewMimeType, mimeType };
        return fileBase;
      } catch (er) {
        self.notify.error(er);
        return null;
      }
    }),

    copyFiles: flow(function* (ids: string[]) {
      try {
        const result: TStringMap<string> = yield self.transport.post<TStringMap<string>>(
          apiUrls.application.files.copy,
          {
            ids,
          }
        );
        return result;
      } catch (er) {
        self.notify.error(er);
        return null;
      }
    }),
    getContentReplacement: flow(function* (fromOrderId: string) {
      try {
        const replacement: ContentReplacement = yield self.transport.get<any>(
          apiUrls.orders.summary.contentReplacement(self.id, fromOrderId)
        );

        return replacement;
      } catch (er) {
        self.notify.error(er);
        return null;
      }
    }),
  }));

export const orderTitle = (props: { fullInventoryNumber: string; name: string }) =>
  `${props.fullInventoryNumber} ${props.name}`;

export const OrderSummary = OrderSummaryBase.actions((self) => ({
  treat: flow(function* (order: OrderSummarySnapshotType) {
    const outsource = order.objects.reduce((acc, obj) => {
      const units = obj.content.filter((u) => u.outsourced);
      return [...units, ...acc];
    }, [] as WorkTypeLinkSnapshotType[]);

    const spendings: OutsourcedOrderSpendingSnapshotType[] = [];
    for (const unit of outsource) {
      const exists = order.outsourcedOrderSpendins.find((s) => s.contentGuid === unit.guid);
      const label = `${unit.name}. ${unit.description}`;

      if (exists) {
        spendings.push({ ...exists, label });
      } else {
        spendings.push({
          actualPayments: [],
          actualSum: 0,
          contentGuid: unit.guid,
          id: yield self.generateId(),
          outsourcer: null,
          planPayments: [],
          additions: [],
          orderNumber: "",
          startDate: null,
          taskAccepted: false,
          taskAcceptedDate: null,
          taskCompletedDate: null,
          taskIssuedDate: null,
          taskCompleted: false,
          taskIssued: false,
          type: "",
          label,
          sortOrder: 1,
          days: null,
          generatedKey: sortKey(),
        });
      }
    }

    const result: OrderSummarySnapshotType = {
      ...order,
      outsourcedOrderSpendins: spendings,
    };

    return result;
  }),

  refresh(snapshot: any) {
    if (isAlive(self)) {
      self.loadOrderStages(self.id);
      applySnapshot(self, snapshot);
    }
  },

  refreshIpds(snapshot: OrderIpdType[]) {
    if (isAlive(self)) {
      applySnapshot(self.ipds, snapshot);
    }
  },
  refreshTechIndicators(snapshot: OrderTechIndicatorType[]) {
    if (isAlive(self)) {
      applySnapshot(self.techIndicators, snapshot);
    }
  },
  refreshOrderAgents(snapshot: AgentType[]) {
    if (isAlive(self)) {
      applySnapshot(self.orderAgents, snapshot);
    }
  },
  refreshOrderCommunication(snapshot: OrderCommunicationSnapshotType) {
    if (isAlive(self)) {
      applySnapshot(self.orderCommunication, snapshot);
    }
  },

  setPrice(price: number) {
    self.price = price;
  },
}))
  .actions((self) => ({
    load: flow(function* (id: string, short = false) {
      try {
        const url = short ? apiUrls.orders.summary.short(id) : apiUrls.orders.summary.details(id);

        const snapshot: OrderSummarySnapshotType = yield self.transport.get<any>(url);
        applySnapshot(self, snapshot);
        const access: string[] = getParent(getParent(self)).session.access;
        if (can(func.ORDERS_FINANCE_READ, access)) {
          self.loadOrderStages(id);
        }

        return snapshot;
      } catch (er) {
        self.notify.error(er);
        return null;
      }
    }),
    loadfromOther: flow(function* (id: string, short = false) {
      try {
        const url = short ? apiUrls.orders.summary.short(id) : apiUrls.orders.summary.details(id);
        const snapshot: OrderSummarySnapshotType = yield self.transport.get<any>(url);
        applySnapshot(self, snapshot);
        return snapshot;
      } catch (er) {
        self.notify.error(er);
        return null;
      }
    }),
    preparePath(patch: TStringMap<any>) {
      if (patch[fields.flags] === "municipal") {
        patch[fields.municipalContract] = true;
        patch[fields.batchContract] = false;
        patch[fields.federalContract] = false;
        patch[fields.regionContract] = false;
      }
      if (patch[fields.flags] === "batch") {
        patch[fields.municipalContract] = false;
        patch[fields.batchContract] = true;
        patch[fields.federalContract] = false;
        patch[fields.regionContract] = false;
      }
      if (patch[fields.flags] === "region") {
        patch[fields.municipalContract] = false;
        patch[fields.batchContract] = false;
        patch[fields.federalContract] = false;
        patch[fields.regionContract] = true;
      }
      if (patch[fields.flags] === "federal") {
        patch[fields.municipalContract] = false;
        patch[fields.batchContract] = false;
        patch[fields.federalContract] = true;
        patch[fields.regionContract] = false;
      }
      if (patch[fields.flags] === "") {
        patch[fields.municipalContract] = false;
        patch[fields.batchContract] = false;
      }
      if (patch[fields.startDate] instanceof Date) {
        patch[fields.startDate] = formatDate(patch[fields.startDate]);
      }
      if (patch[fields.stopDateActual] instanceof Date) {
        patch[fields.stopDateActual] = formatDate(patch[fields.stopDateActual]);
      }
      if (patch[fields.feedbackFileId]) {
        patch[fields.feedbackFileId] = (patch[fields.feedbackFileId] as ClientFeedbackFileType).fileId;
      }
      if (Array.isArray(patch[fields.outsourcedOrderSpendins])) {
        patch[fields.outsourcedOrderSpendins].forEach((item: any) => {
          if (Array.isArray(item[outsourcedSpendingFields.actualPayments])) {
            item[outsourcedSpendingFields.actualPayments].forEach((sp: any) => {
              sp[paymentFields.date] = formatDate(sp[paymentFields.date]);
            });
          }
          if (Array.isArray(item[outsourcedSpendingFields.planPayments])) {
            item[outsourcedSpendingFields.planPayments].forEach((sp: any) => {
              sp[paymentFields.date] = formatDate(sp[paymentFields.date]);
            });
          }
          if (item[outsourcedSpendingFields.startDate] instanceof Date) {
            item[outsourcedSpendingFields.startDate] = formatDate(item[outsourcedSpendingFields.startDate]);
          }
        });
      }
      if (Array.isArray(patch[fields.otherOrderSpendings])) {
        patch[fields.otherOrderSpendings].forEach((item: any) => {
          if (Array.isArray(item[outsourcedSpendingFields.actualPayments])) {
            item[outsourcedSpendingFields.actualPayments].forEach((sp: any) => {
              sp[paymentFields.date] = formatDate(sp[paymentFields.date]);
            });
          }
          if (Array.isArray(item[outsourcedSpendingFields.planPayments])) {
            item[outsourcedSpendingFields.planPayments].forEach((sp: any) => {
              sp[paymentFields.date] = formatDate(sp[paymentFields.date]);
            });
          }
          if (item[outsourcedSpendingFields.startDate] instanceof Date) {
            item[outsourcedSpendingFields.startDate] = formatDate(item[outsourcedSpendingFields.startDate]);
          }
        });
      }

      if (Array.isArray(patch[fields.tripOrderSpendings])) {
        patch[fields.tripOrderSpendings].forEach((item: any) => {
          if (Array.isArray(item[tripSpendingFields.actualPayments])) {
            item[tripSpendingFields.actualPayments].forEach((sp: any) => {
              sp[paymentFields.date] = formatDate(sp[paymentFields.date]);
            });
          }
          if (Array.isArray(item[tripSpendingFields.planPayments])) {
            item[tripSpendingFields.planPayments].forEach((sp: any) => {
              sp[paymentFields.date] = formatDate(sp[paymentFields.date]);
            });
          }
        });
      }
      if (Array.isArray(patch[fields.orderIpds])) {
        patch[fields.orderIpds].forEach((item: any) => {
          item[ipdFields.documentFile] = null;
          item[ipdFields.requestFile] = null;
        });
      }
      // if (Array.isArray(patch[fields.consignments])) {
      //     patch[fields.consignments].forEach((item: any) => {
      //         item[consignmentFileFields.signDate] = formatDate(item[consignmentFileFields.signDate]);
      //     });
      // }
      if (Array.isArray(patch[fields.additions])) {
        patch[fields.additions].forEach((item: any) => {
          item[fields.startDate] = formatDate(item[fields.startDate]);
        });
      }

      return patch;
    },

    emptyObject: flow(function* (inventoryNumber: number) {
      return emptyObject(yield self.generateGuid(), inventoryNumber);
    }),

    emptyCompletion: flow(function* (index: number) {
      return emptyCompletion(yield self.generateGuid(), index);
    }),

    emptyPayment: flow(function* (index: number) {
      return emptyPayment(yield self.generateGuid(), index);
    }),

    emptyOutsourcerPayment: flow(function* (index: number) {
      return emptyOutsourcerPayment(yield self.generateGuid(), index);
    }),

    emptyOwnSpending: flow(function* (unit: WorkTypeLinkSnapshotType, sortOrder: number) {
      return emptyOwnSpending(yield self.generateId(), unit, sortOrder);
    }),

    emptyOtherSpending: flow(function* (sortOrder: number) {
      return emptyOtherSpending(yield self.generateId(), sortOrder);
    }),

    emptyOutsourcedSpending: flow(function* (unit: WorkTypeLinkSnapshotType, sortOrder: number) {
      return emptyOutsourcedSpending(yield self.generateId(), unit, sortOrder);
    }),

    emptyTripSpending: flow(function* (unit: string) {
      return emptyTripSpending(yield self.generateId(), unit);
    }),

    emptyContentFromWorkType: flow(function* (
      orderNumber: string,
      objectNumber: number,
      objectName: string,
      type: WorkTypeDictionaryItemSnapshotType,
      sortOrder: number
    ) {
      const inventory = objectNumber > 0 ? fullInventoryNumber(objectNumber) : "";

      const parts = [orderNumber, inventory, type.label].filter((part) => !!part);

      const result: WorkTypeLinkSnapshotType = {
        orderNumber,
        lastModified: formatDate(new Date()),
        progress: 0,
        workTypeId: type.id,
        workTypeCategory: type.workTypeCategory,
        description: `${trimEnd(".", type.description)}. ${objectName}`,
        guid: yield self.generateGuid(),
        name: parts.join(WORK_CODE_SEPARATOR),
        outsourced: false,
        sortOrder,
        partNumber: "",
        mentor: null,
        mentorId: null,
        defaultMentor: null,
        stampFile: null,
      };

      return result;
    }),

    emptyContent: flow(function* (orderNumber: string, sortOrder: number) {
      const result = emptyWorkTypeLink(yield self.generateGuid());
      result.orderNumber = orderNumber;
      result.sortOrder = sortOrder;

      return result;
    }),

    emptyIpd: flow(function* () {
      const guid = yield self.generateGuid();
      const resut: SaveOrderIpd = {
        description: "",
        documentFile: null,
        guid,
        name: "",
        number: "",
        orderIpdTypeId: "",
        requestFile: null,
        stageGuid: null,
        status: "",
        clientIsMentor: false,
      };
      return resut;
    }),
    emptyTechIndicator: flow(function* () {
      const guid = yield self.generateGuid();
      const result: SaveOrderTechIndicator = {
        guid,
        orderIndicatorName: "",
        number: "",
        orderIndicatorId: "",
      };
      return result;
    }),

    emptyOrderAgent: flow(function* (id: string, reward: string) {
      const guid = yield self.generateGuid();
      const result: SaveAgent = {
        guid,
        agentId: id,
        newReward: String(reward),
        price: "",
        description: "",
        deal: "",
        created: "",
        updated: "",
        materials: [],
        deals: [],
      };
      return result;
    }),
    emptyOrderCommunicationRow: flow(function* () {
      const guid = yield self.generateGuid();
      const result: OrderCommunicationRowType = {
        guid,
        employer: "",
        employerName: "",
        employerPhone: "",
        employerEmail: "",
        employerCompanyName: "",
        timeDescription: "",
        responses: cast([]),
      };
      return result;
    }),

    emptyIpdFromDictionary: flow(function* (item: IpdTypeDictionaryItemType) {
      const guid = yield self.generateGuid();
      const resut: SaveOrderIpd = {
        description: item.description,
        documentFile: null,
        guid,
        name: item.label,
        number: "",
        orderIpdTypeId: item.id,
        requestFile: null,
        stageGuid: null,
        status: "",
        clientIsMentor: false,
      };
      return resut;
    }),

    // emptyConsignment: flow(function* () {
    //     const guid = yield self.generateGuid();
    //     return emptyConsignment(guid);
    // }),

    emptyAddition: flow(function* () {
      const result: OrderFileSnapshotType = {
        fileId: "",
        fileName: "",
        guid: yield self.generateId(),
        orderNumber: "",
        startDate: null,
        sortOrder: 0,
        employerId: EMPTY_OBJECT_ID,
        mimeType: "",
        previewMimeType: "",
      };

      return result;
    }),
    emptyOrderBaseWithName: flow(function* () {
      const result: OrderFileSnapshotType = {
        fileId: "",
        fileName: "",
        guid: yield self.generateId(),
        orderNumber: "",
        startDate: null,
        sortOrder: 0,
        employerId: EMPTY_OBJECT_ID,
        mimeType: "",
        previewMimeType: "",
      };

      return result;
    }),
  }))
  .named("OrderSummary");

export const onlyPremiumsInPatch = (patch: TStringMap<any>) => {
  return fields.premiums in patch && Object.keys(patch).length === 1;
};

export const onlyTasksInPatch = (patch: TStringMap<any>) => {
  return fields.contentTasks in patch && Object.keys(patch).length === 1;
};

export const ipdsInPatch = (patch: TStringMap<any>) => {
  return fields.orderIpds in patch;
};

export const initialState = (hidePrice: boolean): OrderSummarySnapshotType => ({
  id: EMPTY_OBJECT_ID,
  created: moment().format(DATE_TIME_FORMAT),
  name: "",
  color: WHITE,
  startDate: "",
  stopDateActual: null,
  stopDatePlan: null,
  stopDayPlan: null,
  stopDatePlanWarning: null,
  fullName: "",
  orderNumber: "",
  cypher: "",
  inventoryNumber: 0,
  batchContract: false,
  municipalContract: false,
  regionContract: false,
  federalContract: false,
  fullInventoryNumber: "",
  projectPortfolio: null,
  orderStatusLink: null,
  client: null,
  orderType: null,
  productionStage: null,
  employer: null,
  objects: [emptyObject(v4(), 0)],
  financeStages: [],
  planPayments: [],
  ownOrderSpendings: [],
  outsourcedOrderSpendins: [],
  otherOrderSpendings: [],
  tripOrderSpendings: [],
  timesheetSpendings: {
    rows: [],
    departmentMap: [],
    workTypeMap: [],
    showMinutes: false,
  },
  additions: [],
  otherOrders: [],
  additionalOrders: [],
  expertises: null,
  premiumGipPercents: Constants.premiumGipPercents,
  premiumNonProductionPercents: Constants.premiumNonProductionPercents,
  premiumPercents: Constants.premiumNonProductionPercents,
  premiumProductionPercents: Constants.premiumProductionPercents,
  planProductionSpendingsPercents: 0,
  progress: 0,
  ipds: [],
  techIndicators: [],
  contentTasks: [],
  financeValues: emptyFinanceValues(),
  feedbackFile: null,
  premiumBank: {
    calculatedSumm: 0,
    correctionDetails: null,
  },
  employeeFeedback: {},
  finValuesActualOverheadPercents: null,
  finValuesPlanOverheadPercents: null,
  wopiToken: "",
  editorUrl: "",
  canCreateTaskVersion: false,
  hidePrice,
  isInternal: false,
  canClientDownloadTasks: false,
  price: 0,
  stages: [],
  indicators: [],
  orderCommunication: initialOrderCommunication(),
  isConfirmed: false,
  isGip: false,
  orderAgents: [],
  orderAgentPayments: [],
});

export const fields = {
  // order
  name: nameof((a: OrderSummaryType) => a.name) as string,
  isConfirmed: nameof((a: OrderSummaryType) => a.isConfirmed) as string,
  fullName: nameof((a: OrderSummaryType) => a.fullName) as string,
  startDate: nameof((a: OrderSummaryType) => a.startDate) as string,
  stopDatePlan: nameof((a: OrderSummaryType) => a.stopDatePlan) as string,
  stopDateActual: nameof((a: OrderSummaryType) => a.stopDateActual) as string,
  orderNumber: nameof((a: OrderSummaryType) => a.orderNumber) as string,
  inventoryNumber: nameof((a: OrderSummaryType) => a.inventoryNumber) as string,
  fullInventoryNumber: nameof((a: OrderSummaryType) => a.fullInventoryNumber) as string,
  municipalContract: nameof((a: OrderSummaryType) => a.municipalContract) as string,
  batchContract: nameof((a: OrderSummaryType) => a.batchContract) as string,
  regionContract: nameof((a: OrderSummaryType) => a.regionContract) as string,
  canClientDownloadTasks: nameof((a: OrderSummaryType) => a.canClientDownloadTasks) as string,
  federalContract: nameof((a: OrderSummaryType) => a.federalContract) as string,
  portfolioId: nameof((a: OrderSummaryType) => a.projectPortfolioId) as string,
  orderStatusId: nameof((a: OrderSummaryType) => a.orderStatusId) as string,
  clientId: nameof((a: OrderSummaryType) => a.clientId) as string,
  orderTypeId: nameof((a: OrderSummaryType) => a.orderTypeId) as string,
  productionStageId: nameof((a: OrderSummaryType) => a.productionStageId) as string,
  engineerId: nameof((a: OrderSummaryType) => a.engineerId) as string,
  color: nameof((a: OrderSummaryType) => a.color) as string,
  objects: nameof((a: OrderSummaryType) => a.objects) as string,
  isInternal: nameof((a: OrderSummaryType) => a.isInternal) as string,
  otherOrders: nameof((a: OrderSummaryType) => a.otherOrders) as string,
  additionalOrders: nameof((a: OrderSummaryType) => a.additionalOrders) as string,
  additions: nameof((a: OrderSummaryType) => a.additions) as string,
  expertises: nameof((a: OrderSummaryType) => a.expertises) as string,
  cypher: nameof((a: OrderSummaryType) => a.cypher) as string,
  ownOrderSpendings: "ownSpendings",
  outsourcedOrderSpendins: "outsourcedSpendings",
  otherOrderSpendings: "otherSpendings",
  tripOrderSpendings: "tripSpendings",
  price: nameof((a: OrderSummaryType) => a.price) as string,
  premiumGipPercents: nameof((a: OrderSummaryType) => a.premiumGipPercents) as string,
  premiumNonProductionPercents: nameof((a: OrderSummaryType) => a.premiumNonProductionPercents) as string,
  premiumPercents: nameof((a: OrderSummaryType) => a.premiumPercents) as string,
  premiumProductionPercents: nameof((a: OrderSummaryType) => a.premiumProductionPercents) as string,
  planProductionSpendingsPercents: nameof((a: OrderSummaryType) => a.planProductionSpendingsPercents) as string,
  feedbackFileId: nameof((a: OrderSummaryType) => a.feedbackFileId) as string,
  employeeFeedback: nameof((a: OrderSummaryType) => a.employeeFeedback) as string,
  finValuesActualOverheadPercents: nameof((a: OrderSummaryType) => a.finValuesActualOverheadPercents) as string,
  finValuesPlanOverheadPercents: nameof((a: OrderSummaryType) => a.finValuesPlanOverheadPercents) as string,
  //
  orderIpds: "orderIpds",
  orderTechIndicators: "orderTechIndicators",
  orderCommunication: nameof((a: OrderSummaryType) => a.orderCommunication) as string,
  flags: "flags",
  premiums: "premiums",
  contentTasks: "contentTasks",
  orderAgents: "orderAgents",
  orderAgentPayments: "orderAgentPayments",
  orderConfirm: "orderConfirm",
};

export const objectFields = {
  guid: nameof((a: OrderObjectType) => a.guid) as string,
  name: nameof((a: OrderObjectType) => a.name) as string,
  inventoryNumber: nameof((a: OrderObjectType) => a.inventoryNumber) as string,
  fullInventoryNumber: nameof((a: OrderObjectType) => a.fullInventoryNumber) as string,
};

const emptyObject = (guid: string, inventoryNumber: number): OrderObjectSnapshotType => ({
  guid,
  inventoryNumber,
  name: "",
  content: [],
});

const emptyPayment = (guid: string, index: number): OrderPaymentSnapshotType => ({
  created: formatDate(new Date()),
  date: "",
  day: "",
  guid,
  name: index.toString().padStart(5, "0"),
  sum: 0,
  sortableDate: "",
  money: formatMoney(0),
  comment: "",
  hasDate: null,
  type: "",
});

const emptyOwnSpending = (
  id: string,
  unit: WorkTypeLinkSnapshotType,
  sortOrder: number
): OwnOrderSpendingSnapshotType => ({
  contentGuid: unit.guid,
  hours: 0,
  employer: emptyLink(),
  id,
  spending: 0,
  type: "",
  sortOrder,
  generatedKey: sortKey(),
});

const emptyOtherSpending = (id: string, sortOrder: number): OtherOrderSpendingSnapshotType => ({
  contentGuid: "",
  outsourcer: emptyLink(),
  id,
  type: "",
  actualPayments: [],
  actualSum: 0,
  label: "",
  planPayments: [],
  additions: [],
  orderNumber: "",
  startDate: null,
  taskAccepted: false,
  taskCompleted: false,
  taskIssued: false,
  taskAcceptedDate: null,
  taskCompletedDate: null,
  taskIssuedDate: null,
  comment: "",
  sortOrder,
  [outsourcedSpendingFields.outsourcerId]: "",
  days: null,
  generatedKey: sortKey(),
});

export const emptyOutsourcedSpending = (
  id: string,
  unit: WorkTypeLinkSnapshotType,
  sortOrder: number
): OutsourcedOrderSpendingSnapshotType => ({
  contentGuid: unit.guid,
  outsourcer: emptyLink(),
  id,
  type: "",
  actualPayments: [],
  actualSum: 0,
  label: unit.name + ". " + unit.description,
  planPayments: [],
  additions: [],
  orderNumber: "",
  startDate: null,
  taskAccepted: false,
  taskCompleted: false,
  taskIssued: false,
  taskAcceptedDate: null,
  taskCompletedDate: null,
  taskIssuedDate: null,
  sortOrder,
  [outsourcedSpendingFields.outsourcerId]: "",
  days: null,
  generatedKey: sortKey(),
});

export const emptyTripSpending = (id: string, unit: string): TripOrderSpendingSnapshotType => ({
  contentGuid: "",
  outsourcer: emptyLink(),
  id,
  type: "",
  actualPayments: [],
  actualSum: 0,
  label: "",
  planPayments: [],
  additions: [],
  orderNumber: "",
  startDate: null,
  taskAccepted: false,
  taskCompleted: false,
  taskIssued: false,
  taskAcceptedDate: null,
  taskCompletedDate: null,
  taskIssuedDate: null,
  employerId: "",
  sortOrder: 0,
  days: null,
  generatedKey: sortKey(),
});

export type OrderSummaryType = typeof OrderSummaryBase.Type;
export type OrderSummarySnapshotType = typeof OrderSummaryBase.SnapshotType;
export type OrderObjectType = typeof OrderObject.Type;
export type OrderObjectSnapshotType = typeof OrderObject.SnapshotType;
export type WorkTypeLinkType = typeof OrderContentWorkTypeLink.Type;
export type WorkTypeLinkSnapshotType = typeof OrderContentWorkTypeLink.SnapshotType;
export type OrderBaseSnapshotType = typeof OrderBase.SnapshotType;
export type OrderFileType = typeof OrderFile.Type;
export type ExpertiseFileType = typeof ExpertiseFile.Type;
export type SentExpertiseFileType = typeof SentExpertiseFile.Type;
export type OrderIpdType = typeof OrderIpd.Type;
export type OrderTechIndicatorType = typeof OrderTechIndicator.Type;
export type OrderCommunicationType = typeof OrderCommunication.Type;
export type OrderCommunicationSnapshotType = typeof OrderCommunication.SnapshotType;
export type FinanceValuesType = typeof FinanceValues.Type;
export type FinanceValuesSnapshotType = typeof FinanceValues.SnapshotType;
export type OrderIpdSnapshotType = typeof OrderIpd.SnapshotType;

export const PARAM_SECTION = "expanded";
export const PARAM_RECORD = "rowId";

export function OrderExternalParams(locationHash: string) {
  const hash = trimStart("#", locationHash);

  if (hash) {
    const metadata = parse(hash);
    const expanded = (metadata[PARAM_SECTION] as string) || "";
    const rowId = (metadata[PARAM_RECORD] as string) || "";

    return { expanded, rowId };
  }

  return { rowId: "", expanded: "" };
}

export const getContentUnits = (order: OrderSummarySnapshotType, outsourced?: boolean): WorkTypeLinkSnapshotType[] => {
  const orderObjects = order.objects;
  // show only filled rows and default row
  // default row should be first
  const objects = orderObjects.filter((o) => !!o.name || o.inventoryNumber === 0);

  // use only onw units
  const content = objects.reduce((res, object) => {
    const appendix =
      typeof outsourced === "undefined"
        ? object.content
        : object.content.filter((unit) => unit.outsourced === outsourced);

    return [...res, ...appendix];
  }, [] as WorkTypeLinkSnapshotType[]);

  return sortBy(content, (c) => c.sortOrder);
};

export interface ContentReplacement {
  /** Список объектов для замены */
  orderObjects: OrderObjectSnapshotType[];
  /** Список разделов, которые не будут заменены */
  willBeKept: string;
}

const sortKey = () => formatDate(new Date(), SORTABLE_DATE_TIME_FORMAT);

export const emptyWorkTypeLink = (guid: string): WorkTypeLinkSnapshotType => ({
  orderNumber: "",
  lastModified: formatDate(new Date()),
  progress: 0,
  workTypeId: "",
  workTypeCategory: "",
  description: "",
  guid,
  name: "",
  outsourced: false,
  sortOrder: 0,
  partNumber: "",
  mentorId: null,
  mentor: null,
  defaultMentor: null,
  stampFile: null,
});
