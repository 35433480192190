import { observer } from "mobx-react";
import OutsourcersPage from "modules/agents/outsourcers/components/OutsourcersPage";
import { ProtectedRoute } from "modules/common/components/routing/ProtectedRoute";
import { routes } from "modules/common/routes";
import { can, canAny } from "modules/session/auth/access";
import { SessionType } from "modules/session/auth/models/session";
import React from "react";
import { AgentsTabsPage } from "./components/AgentsTabsPage";
import functionalities from "./functionalities";
import { OutsourcersStoreType } from "./outsourcers/models/outsourcers-store";

const READ = Object.values(functionalities).filter((func) => func.endsWith("_READ"));

export const Routes = observer(({ session, outsourcers, baseUrl }: RoutesProps) => {
    const { loggedIn, access } = session;
    const visible = canAny(READ, access);

    return (
        <ProtectedRoute
            path={routes.agents.path}
            loggedIn={loggedIn}
            render={(props) =>
                visible ? (
                    <AgentsTabsPage
                        {...props}
                        outsourcersPanel={
                            can(functionalities.OUTSOURCER_READ, access) ? (
                                <OutsourcersPage
                                    {...props}
                                    store={outsourcers}
                                    access={access}
                                    isLegalEntities={true}
                                    baseUrl={baseUrl}
                                />
                            ) : null
                        }
                        // clientsPanel={
                        //     can(functionalities.CLIENT_READ, access) ? (
                        //         <ClientsPage store={clients} access={access} />
                        //     ) : null
                        // }
                        // suppliersPanel={
                        //     can(functionalities.SUPPLIER_READ, access) ? (
                        //         <SuppliersPage store={suppliers} access={access} baseUrl={baseUrl} />
                        //     ) : null
                        // }
                    />
                ) : null
            }
        />
    );
});

interface RoutesProps {
    baseUrl: string;
    outsourcers: OutsourcersStoreType;
    // clients: ClientsStoreType;
    session: SessionType;
    // suppliers: SuppliersStoreType;
}
