import { Dialog } from "@blueprintjs/core";
import { History, Location, UnregisterCallback } from "history";
import { observer } from "mobx-react";
import func from "modules/agents/functionalities";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { Block } from "modules/common/components/page/Block";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { can } from "modules/session/auth/access";
import React from "react";
import { OutsourcersStoreType } from "../../models/outsourcers-store";
import { OutsourcerDetails } from "../details/OutsourcerDetails";
import { OutsourcersList } from "../list/OutsourcersList";
import styles from "./OutsourcerRequests.module.scss";

class OutsourcerRequests extends React.Component<OutsourcerRequestsProps, OutsourcerRequestsState> {
  private unregister: UnregisterCallback | null = null;

  constructor(props: OutsourcerRequestsProps) {
    super(props);

    this.state = {
      showAccess: false,
      load: false,
    };
  }

  componentWillUnmount() {
    this.unregister && this.unregister();
  }

  render() {
    const { store, access, baseUrl } = this.props;
    const { loading } = store;
    const { showAccess } = this.state;
    const canChange = can(func.OUTSOURCER_EDIT, access);
    const canConfirm = can(func.OUTSOURCER_CONFIRMATION, access);
    const canSupplierOrder = can(func.AGENTS_SUPPLIER_ORDER, access);

    return (
      <Block className={styles.page}>
        <Dialog
          title={"Агент"}
          isOpen={showAccess}
          backdropClassName="standard"
          onClose={this.toggleShowAccess}
          style={{ width: "890px" }}
        >
          <OutsourcerDetails
            baseUrl={baseUrl}
            access={access}
            canChange={canChange}
            canRemove={canChange}
            canConfirm={canConfirm}
            canSupplierOrder={canSupplierOrder}
            workTypes={store.workTypes}
            outsourcer={store.requestDetails}
            onSaved={this.onOutsourcerSaved}
            onRemoved={this.onOutsourcerRemoved}
            onPrintCredentials={store.printCredentials}
            onPrintDocCredentials={this.props.store.printDocCredentials}
            confirmRequest={this.props.store.confirmRequest}
            showSensitiveData={false}
            categories={store.categories}
            noOrder
            isAgentRequest={true}
          />
        </Dialog>
        {loading ? (
          <LocalLoader active zIndex={0} />
        ) : (
          <OutsourcersList
            isRequestAgent
            store={store.requestsList}
            onOutsourcerSelect={this.onOutsourcerSelected}
            onOutsourcerRemoved={this.onOutsourcerRemoved}
          />
        )}
      </Block>
    );
  }

  toggleShowAccess = () => this.setState({ showAccess: !this.state.showAccess });

  onOutsourcerSelected = async (outsourcerId: string = "") => {
    await this.props.store.requestDetails.loadRequestDetails(outsourcerId);
    this.toggleShowAccess();
  };

  onOutsourcerSaved = () => {
    this.onOutsourcerSelected("");
    this.loadList();
  };

  onOutsourcerRemoved = () => {
    this.onOutsourcerSelected("");
    this.loadList();
  };

  onOutsourcerAdd = () => {
    this.onOutsourcerSelected(EMPTY_OBJECT_ID);
  };

  loadList = () => {
    this.props.store.loadRequests();
    this.props.store.load();
  };

  showAccess = () => this.setState({ showAccess: true });
  hideAccess = () => this.setState({ showAccess: false });

  setFunctionality = (roleId: string, functionality: string, state: boolean) =>
    this.props.store.requestDetails.setFunctionality(functionality, state);
}

export default observer(OutsourcerRequests);

interface OutsourcerRequestsProps {
  store: OutsourcersStoreType;
  isLegalEntities: boolean;
  access: string[];
  history: History;
  location: Location;
  baseUrl: string;
}

interface OutsourcersId {
  outsourcerId: string;
}

interface OutsourcerRequestsState {
  showAccess: boolean;
  load: boolean;
}
